export const environment = {
  firebase: {
    projectId: 'coco-6c8a7',
    appId: '1:1091082167973:web:b429f13a0db7c170668f4c',
    storageBucket: 'coco-6c8a7.appspot.com',
    locationId: 'asia-southeast2',
    apiKey: 'AIzaSyAfoY5Dve1Yp8Ho8GvVmU8LZwhTzauYR68',
    authDomain: 'coco-6c8a7.firebaseapp.com',
    messagingSenderId: '1091082167973',
    measurementId: 'G-P4TS40EDVY',
  },
  production: true
};
